export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			MAP: 'Map',
			RESERVATION: 'Reservation/Irrigation',
			DIRECTORATES: {
				MAIN: 'Directorates',
				MANAGE: 'Directorate Management',
				USERS: 'Directorate Users'
			},
			COOPS: {
				MAIN: 'Coops',
				MANAGE: 'Coop Management',
				USERS: 'Coop Users',
				WELLS: 'Wells',
				HYDRANTS: 'Hydrants',
				TANKS: 'Tanks',
				FIELDS: 'Fields',
				SETTINGS: 'Settings',
				PRICETARIFFS: 'Price Tariffs',
				LINES: 'Lines',
				MAINS: 'Mains'
			},
			MEMBERS: {
				MAIN: 'Members',
				MANAGE: 'Member Management',
				USERS: 'Member Users'
			},
			REPORTS: {
				MAIN: 'Reports',
				WELL_PRODUCTION: 'Production Report',
				MEMBER_PRODUCTION: 'Member Production Report',
				FIELD_PRODUCTION: 'Field Production Report',
				MEMBER_CONSUMPTION: 'Member Consumption Report',
				MEMBER_CASH: 'Member Cash Report',
				FIELD_WELL_CONSUMPTION: 'Field-Well Consumption Report',
				CROP_IRRIGATION: 'Crop Irrigation Report',
				INCOME_EXPENSE: 'Income Expense Report',
				IRRIGATION_HISTORY: 'Irrigation History',
				DESCRIPTIONS:{
					FIELD_PRODUCTION:'In this report, the amount of water irrigated by the selected cooperative members for all fields or the selected field, the active period spent and the total amount during this period can be seen on a yearly basis. If a field is selected from the filters, this information is displayed for that field by year. If not, this information is shown for all fields.',
					FIELD_WELL_CONSUMPTION:'In this report, after selecting a field, members can see the amount of water irrigated for that field in that year or month, the active time spent and the total amount information for that field, both on a yearly and monthly basis.',
					MEMBER_CONSUMPTION:'In this report, the amount of water used and the amount spent by all members of the selected cooperative can be tracked by year, and the total active working period consumed is displayed. There is also a member storage feature. In this way, a member is created and the duration of the member, the amount of water used and the amount can be reported.',
					MEMBER_CASH:'There were systems in place for members to refund their accounts, transaction dates and payment types. The money deposited and spent by the person making the transaction and the remaining balance information are tabulated with the date, hour and minute of the transaction. You can see the users last balance information and the last information from their account in detail here. The receipt formula for receipted transactions can be cut by clicking on the receipt button to the right of each data in this report. In addition to the record in the table, the receipt can be printed with the receipt number, receipt text, province, district and date information.',
					INCOME_EXPENSE:'Income and expense report allows viewing total income and expenses separately for each year and month. The expense information included in the report is the amount you spent on irrigation in the selected year/month. The total amount loaded into the system for irrigation is shown in the income information. You can also learn your profit amount with the net information in the report.',
					IRRIGATION_HISTORY:'This is a report containing the active irrigation periods during which all members irrigate from the selected well at all times for each well, and the date, hour and minute information regarding these data. And a specific year can be selected in this report and data for that year can also be reported.',
					WELL_PRODUCTION:'In this report, active storage period, amount information and storable storage of all wells belonging to the cooperative according to years and months. In well-based storage, only the data of the selected well can be seen.',
					MEMBER_PRODUCTION:'This report shows the member-based breakdown of the production from wells and hydrants selected from the wells affiliated to the cooperatives, according to the years and months they requested.',
				}	
			},
			FORMS: {
				MAIN: 'Forms',
				RECEIPT_BOOK: 'Receipt Book',
				IRRIGATION_FORM: 'Irrigation Forms',
				MONTHLY_SUMMARY: 'Monthly Summary',
			},
			SETTINGS: {
				MAIN: 'Settings',
				SMS_SETTINGS: 'SMS Settings',
				EMAIL_SETTINGS: 'E-Mail Settings'

			},
			QUICKACTIONS: {
				MAIN: 'Quick Actions',
				SHORTCUTS: 'Shortcuts',
				MEMBER_MANAGEMENT: 'Members',
				FIELD_MANAGEMENT: 'Fields',
				WELL_MANAGEMENT: 'Wells',
			},
			USER_PROFILE: {
				MY_PROFILE: 'My Profile',
			},
			AUTOMATIONS: 'Automations',
			CROPS: 'Crops',
			FAQ: 'Frequently Asked Questions',
			ANNOUNCEMENTS: 'Announcements'
		},
		AUTH: {
			GENERAL: {
				OR: 'or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				USERNAME: 'Username',
				PASSWORD: 'Password',

			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email or phone number to reset your password:',
				SUCCESS_EMAIL: 'Information to reset your password is sent to your email',
				SUCCESS_PHONE: 'The code to reset your password is sent to your mobile',
				SUCCESS: 'You can reset your password',
				USER_NOT_FOUND: 'User not found',
				NEW_CODE_BUTTON: 'Request new code',
				TIME: 'Your time is up',
				EMAIL: 'E-Mail'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				PHONE: 'Phone Number',
				IDENTITYNUMBER: 'Identity Number',
				REGISTRATIONNUMBER: 'Registration Number',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect'
			},
			LOGOUT: {
				BUTTON: 'Logout'
			},
			DELETE_ACCOUNT: 'Delete My Account'

		},
		IRRIMET: {
			COPYRIGHT: 'Irrimet Irrigation Automation System',
			ANNOUNCEMENT: {
				CREATE: 'Create Announcement',
				UPDATE: 'Update Announcement',
				TITLE : 'Title',
				ENTER_TITLE : 'Please enter title',
				ENTER_ANNOUNCEMENT : 'Please enter announcement',
				ANNOUNCEMENT: 'Announcement',
				ANNOUNCEMENTS: 'Announcements',
				ACTIVE: 'Active',
				START_DATE: 'Start Date',
				END_DATE: 'End Date',
				ADD_MESSAGE: 'Announcement has been created',
				UPDATE_MESSAGE: 'Announcement has been updated',
				CONFIRM_DELETE_TITLE: 'Confirm Delete Reservation',
				CONFIRM_DELETE_BODY: 'Do you want to delete the announcement?',
				DELETE_FAILURE: 'Announcement could not be deleted!',
				DELETE_SUCCESS: 'Announcement deleted',
				DELETE: 'Delete Announcement',
				VIEW: 'View Announcement',
				EDIT_BY: 'Edited By',
				EDIT_DATE: 'Edit Date',
				SCOPE: 'Scope',
				SYSTEM: 'System',
				DIRECTORATE: 'Directorate',
				COOPERATIVE: 'Cooperative',
				TARGET: 'Target',
				EVERYBODY: 'Everybody',
			},
			ALARMS: {
				LIST: 'Alarm List'
			},
			EVENTS: {
				LIST: 'Event List'
			},
			COMMON: {
				ERROR: 'Error',
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				CLEAR_FILTER: 'Clear Filter',
				BY_STATE: 'State',
				BY_TYPE: 'Type',
				BUSINESS: 'Business',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'In all fields (min 3 characters)',
				PLEASE: 'Please',
				ACTIONS: 'Actions',
				NAME: 'Name',
				SURNAME: 'Surname',
				NAMEANDSURNAME: 'Name and Surname',
				DISPLAYNAME: 'Display Name',
				CREDIT_LIMIT: 'Credit Limit',
				ON_DEMAND_LIMIT: 'Limit (hr)',
				ON_DEMAND_REMOTE_RFID_CONTROL: 'RFID pauses irrigation',
				DIRECTORATE: 'Directorate',
				COOP: 'Coop',
				MEMBER: 'Member',
				TOKEN: 'Token',
				RFID: 'RFID',
				BALANCE: 'Balance (TL)',
				IDENTITYNUMBER: 'Identity Number',
				REGISTRATIONNUMBER: 'Registration Number',
				PHONE: 'Phone',
				EMAIL: 'Email',
				ADDRESS: 'Address',
				FIRMNAME: 'Company Name',
				TAXNUMBER: 'Tax Number',
				TAXOFFICE: 'Tax Office',
				DESCRIPTION: 'Description',
				DESCRIPTION_SHORT: 'Description',
				ENTER_NAME: 'Enter Name',
				CITY: 'City',
				CITY_UC : 'CITY',
				DISTRICT: 'District',
				DISTRICT_UC: 'DISTRICT',
				ENTER_DESCRIPTION: 'Enter description',
				ENTER_FIRMNAME: 'Enter company name',
				ENTER_SURNAME: 'Enter surname',
				ENTER_ADDRESS: 'Enter address',
				ENTER_PHONE: 'Enter phone',
				ENTER_EMAIL: 'Enter email',
				ENTER_IDENTITYNUMBER: 'Enter identity number',
				ENTER_REGISTRATIONNUMBER: 'Enter registration number',
				ENTER_DISPLAYNAME: 'Enter display name',
				ENTER_TAXNUMBER: 'Enter tax number',
				ENTER_TAXOFFICE: 'Enter tax office',
				ENTER_CREDIT_LIMIT: 'Enter credit limit',
				ENTER_ON_DEMAND_LIMIT: 'Enter on demand irrigation limit (hr)',
				OK: 'OK',
				CANCEL: 'Cancel',
				YES: 'Yes',
				NO: 'No',
				CANCEL_CHANGES: 'Cancel changes',
				SAVE: 'Save',
				SAVE_CHANGEs: 'Save changes',
				DELETE: 'Delete',
				IS_REQUIRED: 'is required',
				NO_RECORDS_FOUND: 'No Records Found',
				FORM_ERROR_ALERT: 'Please change a few things up and try submitting again',
				TODAY: 'Today',
				PREVIOUS: 'Previous',
				NEXT: 'Next',
				YEAR: 'Year',
				MONTH: 'Month',
				WEEK: 'Week',
				DAY: 'Day',
				HOUR: 'hours',
				HOUR_LOWER: 'hour',
				HOUR_SHORT: 'hrs',
				MINUTE: 'minutes',
				MINUTE_SHORT: 'mins',
				SECOND: 'seconds',
				SECOND_SHORT: 'secs',
				START_DATE: 'Start Date',
				END_DATE: 'Cancellation Date',
				STATUS_TOKEN: 'Status',
				SELECT_YEAR: 'Please select year',
				SELECT_MONTH: 'Please select month',
				JANUARY: 'January',
				FEBRUARY: 'February',
				MARCH: 'March',
				APRIL: 'April',
				MAY: 'May',
				JUNE: 'June',
				JULY: 'July',
				AUGUST: 'August',
				SEPTEMBER: 'September',
				OCTOBER: 'October',
				NOVEMBER: 'November',
				DECEMBER: 'December',
				DATE: 'Date',
				INDIVIDUAL: 'Individual',
				CORPORATE: 'Corporate',
				WELL: 'Well',
				HYDRANT: 'Hydrant',
				TANK: 'Tank',
				PARCEL: 'Parcel',
				IRRIGATION_NORMAL: 'Irrigation',
				IRRIGATION_ON_DEMAND: 'On Demand Irrigation',
				RESERVATION_LIST: 'Reservation List',
				REMAINING: 'Remaining',
				ESTIMATED_END: 'End',
				MAX_ESTIMATED_END: 'Max End',
				RESERVATION: 'Reservation',
				TAXNUMBER_VALIDATION_ERR: 'Please enter a valid tax number',
				OR: 'Or',
				PERCENT: 'Percent',
				CALENDAR: 'Calendar',
				EDIT: 'Edit',
				VOLUME: 'Volume',
				TIME: 'Time',
				NO_DATA: 'No Data',
				PARAMETER: 'Parameter',
				PARAMETERS: 'Parameters',
				TOTAL_BALANCE: 'Balance',
				AVAILABLE_BALANCE: 'Available Balance',
				AVAILABLE_BALANCE_SHORT: 'Available',
				TOTAL: 'Total'
			},
			ANNOUNCEBOARD: {
				TITLE: 'Announcement Board',
			},
			DASHBOARD: {
				TITLE: 'Dashboard',
				STATE_COMMUNICATIONERROR: 'Communication Error',
				STATE_IDLE: 'Idle',
				STATE_WAITINGFORCARDREAD: 'Waiting for Card Read',
				STATE_IRRIGATING: 'Irrigating',
				STATE_IRRIGATIONABOUTTOFINISH: 'Irrigation Finishing',
				STATE_IRRIGATIONCOMPLETE: 'Irrigation Complete',
				STATE_PAUSED: 'Paused',
				STATE_FIRMWAREUPDATE: 'Firmware Update',
				ALARM: 'Alarm',
				MORE_PERSON: 'more person...',
			},
			DIRECTORATES: {
				DIRECTORATES: 'Directorates',
				DIRECTORATES_LIST: 'Directorates List',
				NEW_DIRECTORATE: 'New Directorate',
				CREATE_NEW_DIRECTORATE: 'Create New Directorate',
				EDIT_DIRECTORATE: 'Edit Directorate',
				DELETE_DIRECTORATE_SIMPLE: {
					TITLE: 'Directorate Delete',
					DESCRIPTION: 'Are you sure to permanently delete this directorate?',
					WAIT_DESCRIPTION: 'Directorate is deleting...',
					MESSAGE: 'Directorate has been deleted'
				},
				DELETE_DIRECTORATE_MULTY: {
					TITLE: 'Directorates Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected directorates?',
					WAIT_DESCRIPTION: 'Directorates are deleting...',
					MESSAGE: 'Selected directorates have been deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Directorate has been updated',
					ADD_MESSAGE: 'Directorate has been created'
				}
			},
			COOPS: {
				COOPS: 'Coops',
				COOPS_LIST: 'Coops List',
				NEW_COOP: 'New Coop',
				EDIT_COOP: 'Edit Coop',
				SETTING_COOP: 'Setting Coop',
				COOP_CREDITCARD_RATES: 'Credit Cart Comission Rates',
				CREATE_NEW_COOP: 'Create New Coop',
				SELECT_DIRECTORATE: 'Select directorate',
				DELETE_COOP_SIMPLE: {
					TITLE: 'Coop Delete',
					DESCRIPTION: 'Are you sure to permanently delete this coop?',
					WAIT_DESCRIPTION: 'Coop is deleting...',
					MESSAGE: 'Coop has been deleted'
				},
				DELETE_COOP_MULTY: {
					TITLE: 'Coops Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected coops?',
					WAIT_DESCRIPTION: 'Coops are deleting...',
					MESSAGE: 'Selected coops have been deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Coop has been updated',
					ADD_MESSAGE: 'Coop has been created'
				},
				SETTING: {
					UPDATE_MESSAGE: 'Coop settings have been updated',
					SELECT_IRRI_REQUEST_TYPE: 'Select irrigation request type',
					SELECT_PUMPPRICE_TYPE: 'Select pumpprice type',
					SELECT_IRRI_EXTEND_INTERVAL: 'Select irrigation extend interval',
					SELECT_MAX_IRRI_EXT_PERCENT: 'Select max irrigation extend percent ',
					FARMER_RESERVATION: 'Farmer can make reservation',
					FARMER_CAN_EXTEND: 'Farmer can extend the irrigation',
					COOP_USER_RESERVATION:'Coop user can make reservation',
					COOP_USER_CAN_EXTEND: 'Coop user can extend the irrigation',
					UPDATE_MESSAGE_ERR: 'Coop settings have NOT been updated',
					DIFF_MEMBER_MINUTES_BEFORE_FINISH: 'Min minutes to continue irrig for different member',
					SAME_MEMBER_MINUTES_BEFORE_FINISH: 'Min minutes to continue irrig for same member',
					TRANSACTION_ON_CREDIT: 'Transaction on Credit',
					USE_MAXTIMESTAMP: 'User MaxTimestamp',
					FARMER_CAN_CANCEL_RESERVATION: 'Farmer can cancel reservation',
					FARMER_CAN_STOP_IRRIGATION: 'Farmer can stop irrigation',
					COOP_USER_CANCEL_RESERVATION: 'Coop user can cancel reservation',
					COOP_USER_STOP_IRRIGATION: 'Coop user can stop irrigation',
					ON_DEMAND: 'On Demand',
					VALIDATION_EXT_FIELD: 'Enter a value between 1 and 10.',
					VALIDATION_CREDIT_LIMIT: 'Enter a valid credit limit.',
					VALIDATION_ON_DEMAND_LIMIT: 'Enter a valid irrigation limit.',
					PERSONAL: 'Personal',
				}
			},
			CREDIT_CARD_RATE: {
				LIST: 'Credit Card Comission Rate List',
				ADD_NEW: 'Add New',
				NAME: 'Name',
				ENTER_NAME: 'Enter Name',
				RATE: 'Rate',
				RATE_ERROR: 'Invalid comission rate',
				NEW_CREDIT_CARD_RATE: 'New Credit Card Comission Rate',
				ENTER_RATE: 'Enter Rate',
				DELETE: {
					TITLE: 'Delete comission rate',
					DESCRIPTION: 'Are you sure to permanently delete this comission rate?',
					WAIT_DESCRIPTION: 'Comission rate is being deleted...',
					MESSAGE: 'Comission rate has been deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Comission rate is updated',
					ADD_MESSAGE: 'Comission rate is created',
				},
				WITHDRAW_DESCRIPTON: '{{rate}} % Credit card comission for {{amount}} TL payment with receipt number {{rNb}} '
			},
			TOKENS: {
				NEW_TOKEN: 'New Token',
				EDIT_TOKEN: 'Change Status',
				CHANGE_COOP: 'Change Coop',
				CREATE_NEW_TOKEN: 'Create New Token',
				TOKEN: 'Token',
				TOKENS_LIST: 'Token List',
				DELETE_TOKEN_SIMPLE: {
					TITLE: 'Token Delete',
					DESCRIPTION: 'Are you sure to permanently delete this token?',
					WAIT_DESCRIPTION: 'Token is being deleting...',
					MESSAGE: 'Token has been deleted'
				},
				REMOVE_TOKEN_FROM_MEMBER: {
					TITLE: 'Remove Token',
					DESCRIPTION: 'Are you sure to remove this token from the member?',
					WAIT_DESCRIPTION: 'Token is being removed...',
					MESSAGE: 'Token has been removed',
					ERROR: 'Token could not been removed'
				},
				ASSIGN_TOKEN_TO_MEMBER: {
					TITLE: 'Assign Token',
					SELECT_TOKEN: 'Select token',
					ASSIGNED_MESSAGE: 'Token has been assigned',
					NO_TOKEN_LEFT_MESSAGE: 'All tokens defined for the coop are used',
				},
				DELETE_TOKEN_MULTY: {
					TITLE: 'Tokens Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected tokens?',
					WAIT_DESCRIPTION: 'Tokens are deleting...',
					MESSAGE: 'Selected tokens have been deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Token has been updated',
					ADD_MESSAGE: 'Token has been created',
				},
				TOKEN_STATUS_PASSIVE: 'Available',
				TOKEN_STATUS_ACTIVE: 'In Use',
				TOKEN_STATUS_OUT_OF_ORDER: 'Out of Order',
				TOKEN_STATUS_MISSING: 'Missing',
				TOKEN_STATUS_RETURN: 'Return',
				TOKENS_EXIST: '{unsavedRfids} tokens exist on the system',
				CHANGE_DATE: 'Change Date',
				LAST_READ_DATE_RFID: 'Last Read',
				ENTER_TOKEN: 'Enter Token',
				STATUS_SELECT: 'Select change reason',
				LAST_READ_RESULT: {
					TITLE: 'Last Read Result',
					BUSY: 'Server Busy',
					UNKNOWN: 'Unknown',
					UNASSIGNED: 'Unassigned',
					DISABLED: 'Disabled',
					NO_MEMBERSHIP: 'No Membership',
					NO_TASK: 'No Task',
					MULTIPLE_TASKS: 'Multiple Tasks',
					INSUFF_BALANCE: 'Insufficient Balance',
					TOO_EARLY: 'Too Early To Continue',
				}
			},
			MEMBERS: {
				MEMBERS: 'Members',
				MEMBERS_LIST: 'Members List',
				NEW_MEMBER: 'New Member',
				OUTSIDER: 'Outsider',
				EDIT_MEMBER: 'Edit Member',
				CREATE_NEW_MEMBER: 'Create New Member',
				SELECT_DIRECTORATE: 'Select directorate',
				SELECT_COOP: 'Select coop',
				MEMBER_INFO: 'Member Information',
				MEMBER_RESERVATIONS: 'Member Reservations',
				DELETE_MEMBER_SIMPLE: {
					TITLE: 'Member Delete',
					DESCRIPTION: 'Are you sure to permanently delete this member?',
					WAIT_DESCRIPTION: 'Member is deleting...',
					MESSAGE: 'Member has been deleted'
				},
				DELETE_MEMBER_MULTY: {
					TITLE: 'Members Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected members?',
					WAIT_DESCRIPTION: 'Members are deleting...',
					MESSAGE: 'Selected members have been deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Member has been updated',
					ADD_MESSAGE: 'Member has been created'
				},
				EMPTY_COOP_ERR: 'Please select a coop',
				CONFIRM_CREATE_USER: {
					TITLE : 'New User Confirmation',
					BODY : 'Do you want to create a user for the newly created member?'
				}
			},
			CASHACCOUNT: {
				MEMBER_DEPOSIT: 'Deposit',
				MEMBER_WITHDRAW: 'Withdraw',
				PAYMENT_TYPE: 'Payment type',
				SELECT_PAYMENT_TYPE: 'Select payment type',
				EXPENSE_TYPE: 'Expense type',
				SELECT_EXPENSE_TYPE: 'Select expense type',
				ENTER_OPERATION_DATE: 'Select operation date',
				ENTER_DEPOSIT_AMOUNT: 'Enter deposit amount',
				ENTER_WITHDRAW_AMOUNT: 'Enter expense amount',
				DEPOSIT: 'The money has been deposited',
				WITHDRAW: 'The money has been withdrawed',
				AMOUNT_VALIDATION_ERR: 'Please enter correctly formatted quantity',
				INVALID_WITHDRAW: 'You can not request more than your balance ({{cash}} TL)',
			},
			RESERVATION: {
				TITLE: 'Reservation and Irrigation',
				DAY: 'Date',
				PARCEL: 'Parcel',
				MEMBER: 'Member',
				QUANTITY: 'Quantity',
				DURATION: 'Duration',
				STATUS: 'Status',
				SELECT_DIRECTORATE: 'Select directorate',
				SELECT_COOP: 'Select coop',
				SELECT_WELL: 'Select well',
				SELECT_MEMBER: 'Select member',
				SELECT_PARCEL: 'Select parcel',
				NEW_IRRIGATION: 'New Irrigation',
				NEW_IRRIGATION_TOOLTIP: 'Start new irrigation',
				NEW_RESERVATION: 'New Reservation',
				NEW_RESERVATION_TOOLTIP: 'Create new reservation',
				IRRIGATION_REQUEST: 'Irrigation Request',
				IRRIGATION_TIME: 'Irrigation duration',
				IRRIGATION_QUANTITY: 'Irrigation quantity',
				ENTER_IRRIGATION_TIME: 'Please enter irrigation duration',
				ENTER_IRRIGATION_QUANTITY: 'Please enter irrigation quantity',
				CREATE_RES_TITLE: 'Create Reservation',
				CREATE_IRRIG_TITLE: 'Create Irrigation',
				CONFIRM_CANCELRESERVATION_TITLE: 'Confirm Cancel Reservation',
				CONFIRM_CANCELRESERVATION_BODY: 'Do you want to cancel the {{quantity}} m3 reservation on {{day}} for {{wellName}}?',
				CONFIRM_CANCELIRRIGATION_TITLE: 'Confirm Cancel Irrigation',
				// tslint:disable-next-line:max-line-length
				CONFIRM_CANCELIRRIGATION_BODY: 'Do you want to cancel the {{quantity}} m3 irrigation on {{day}} for {{wellName}}?',
				CONFIRM_DECREASE_IRRIGATION_TITLE: 'Confirm Decrease Irrigation Amount',
				CONFIRM_DECREASE_IRRIGATION_BODY: 'Do you want to decrease irrigation amount by {{irrigationExtendInterval}} minutes for {{wellName}}?',
				CONFIRM_INCREASE_IRRIGATION_TITLE: 'Confirm Increase Irrigation Amount',
				CONFIRM_INCREASE_IRRIGATION_BODY: 'Do you want to increase irrigation amount by {{irrigationExtendInterval}} minutes for {{wellName}}?',
				CONFIRM_PAUSE_IRRIGATION_TITLE: 'Confirm Pause Irrigation',
				CONFIRM_PAUSE_IRRIGATION_BODY: 'Do you want to pause irrigation for {{wellName}}?',
				CONFIRM_RESUME_IRRIGATION_TITLE: 'Confirm Resume Irrigation',
				CONFIRM_RESUME_IRRIGATION_BODY: 'Do you want to resume irrigation for {{wellName}}?',
				// tslint:disable-next-line:max-line-length
				CONFIRM_REMOTE_RFID_SCAN_TITLE: 'Confirm Remote RFID Scan',
				CONFIRM_REMOTE_RFID_SCAN_BODY: 'Do you want to start remote RFID scan for {{wellName}}?',
				// tslint:disable-next-line:max-line-length
				CONFIRM_STOP_IRRIGATION_TITLE: 'Confirm Stop Irrigation',
				CONFIRM_STOP_IRRIGATION_BODY: 'Do you want to stop irrigation for {{wellName}}?',
				CONFIRM_DELETE_ACTIVE_IRRIGATION_TITLE: 'Confirm Delete Active Irrigation',
				CONFIRM_DELETE_ACTIVE_IRRIGATION_BODY: 'Do you want to delete the active irrigation for {{wellName}}?',
				SET_TO_IDLE: 'Set to idle',
				REMOTE_RFID_SCAN: 'Scan RFID',
				START_IRRIGATION: 'Start',
				CANCEL_IRRIGATION: 'Cancel',
				STOP_IRRIGATION: 'Stop',
				PAUSE_IRRIGATION: 'Pause',
				INCREASE_QTY: 'Increase quantity',
				DECREASE_QTY: 'Decrease quantity',
				RESUME_IRRIGATION: 'Resume',
				CANCEL_RESERVATION: 'Cancel reservation',
				IRRIGATION_PARTNERS: 'Irrigation Partners',
				ENTER_IRRIGATION_PERCENT: 'Please enter irrigation percent',
				SINGLE_MEMBER_IRRIGATION: 'Single Member Irrigation',
				MULTIPLE_MEMBER_IRRIGATION: 'Multiple Member Irrigation',
				EDIT_IRRIGATION: 'Update',
				EDIT_IRRIGATION_TITLE: 'Update Irrigation',
				EDIT_IRRIGATION_SUCCESS: 'Irrigation updated',
				EDIT_IRRIGATION_FAILURE: 'Irrigation could not be updated',
				MOVE_UP: 'Move Up',
				MOVE_DOWN: 'Move Down',
				NO_PRICE_DEFINED: 'No price defined for this well',
				LAST_COMM_TIME: 'LC',
				RFID_READ_TIME: 'IS',
				LAST_TIMECOUNT_REPORTED: 'LT',
				NONE_FOUND: 'No Reservations Found',
				START_ON_DEMAND_IRRIGATION: 'Start On Demand Irrigation',
				START_ON_DEMAND_IRRIGATION_TOOLTIP: 'Create an on demand irrigation and start'
			},
			RESERVATION_MSGS: {
				SELECT_MEMBER: 'Select a member',
				SELECT_FIELD: 'Select a field',
				SELECT_WELL_FIRST: 'Select a well first',
				TODAY_HAS_RESERVATIONS: 'You can not start an irrigation as there are other reservations for this well',
				FARMERS_CANNOT_START_IRRIGATION: 'Members can not start irrigation!',
				IRRIGATION_REQUEST_SUCCESS: 'Irrigation request successfully sent',
				IRRIGATION_REQUEST_FAILURE: 'Irrigation request failure!',
				SET_TO_IDLE_SUCCESS: 'Set to idle success',
				SET_TO_IDLE_FAILURE: 'Set to idle failure!',
				REMOTE_RFID_SCAN_SUCCESS: 'Remote RFID scan success',
				REMOTE_RFID_SCAN_FAILURE: 'Remote RFID scan failure!',
				CANCEL_IRRIGATION_SUCCESS: 'Irrigation cancelled',
				CANCEL_IRRIGATION_FAILURE: 'Irrigation could not be cancelled!',
				CANCEL_RESERVATION_SUCCESS: 'Reservation cancelled',
				CANCEL_RESERVATION_FAILURE: 'Reservation could not be cancelled!',
				STOP_IRRIGATION_SUCCESS: 'Irrigation stopped',
				STOP_IRRIGATION_FAILURE: 'Irrigation could not be stopped!',
				PAUSE_IRRIGATION_SUCCESS: 'Irrigation paused',
				PAUSE_IRRIGATION_FAILURE: 'Irrigation could not be paused!',
				RESUME_IRRIGATION_SUCCESS: 'Irrigation resumed',
				RESUME_IRRIGATION_FAILURE: 'Irrigation could not be resumed!',
				INCREASE_QTY_SUCCESS: 'Quantity increased',
				INCREASE_QTY_FAILURE: 'Quantity could not be increased!',
				DECREASE_QTY_SUCCESS: 'Quantity decreased',
				DECREASE_QTY_FAILURE: 'Quantity could not be decreased!',
				RESERVATION_CREATED: 'Reservation created',
				INVALID_BALANCE_QTY: 'Insufficient balance for {{member}} (current: {{creditInVolume}} m3, required: {{requiredCreditInVolume m3}})',
				INVALID_BALANCE_TIME: 'Insufficient balance for {{member}} (current: {{creditInHours}} hour, required: {{requiredCreditInHours hour}})',
				INVALID_QTY: 'Requested quantity must be greater than 0',
				INVALID_TIME: 'Requested duration must be greater than 0',
				TOTAL_PERCENT_MUST_BE_100: 'Total irrigaiton percent must be 100%',
				SELECT_IRRIGATION_PARTNERS: 'Please select the irrigation partners',
				ENTER_IRRIGATION_AMOUNT: 'Please enter irrigation duration/qty',
				SELECT_ONE_VALVE: 'Please select at least one outlet',
				START_ON_DEMAND_IRRIGATION_FAILURE: 'You can not start on demand irrigation for this well',
				DELETE_ACTIVE_IRRIGATION_SUCCESS: 'Active Irrigation deleted',
				DELETE_ACTIVE_IRRIGATION_FAILURE: 'Active Irrigation could not be deleted!',
			},
			WELLS: {
				WELL_LIST: 'Well List',
				NEW_WELL: 'New Well',
				CREATE_NEW_WELL: 'Create New Well',
				WELL_NAME: 'Well Name',
				ENTER_WELL_NAME: 'Please enter well name',
				IMEI: 'Imei',
				ENTER_IMEI: 'Please enter imei',
				IMEI_VALIDATION_ERR: 'Imei should be 15 digit number',
				PUMP_ID: 'Pump ID',
				ENTER_PUMP_ID: 'Please enter pump id',
				PUMPID_VALIDATION_ERR: 'Pump id is a required numeric value',
				HAS_DEBIMETER: 'Has Debimeter',
				HAS_REMOTE_RFID_SCAN: 'Has Remote RFID Scan',
				DEBIMETER_ID: 'Debimeter ID',
				ENTER_DEBIMETER_ID: 'Please enter debimeter id',
				DEBIMETERID_VALIDATION_ERR: 'Debimeter is a required numeric value',
				DEBIMETER_COEF: 'Debimeter Coefficient',
				ENTER_DEBIMETER_COEF: 'Pleae enter debimeter coefficient',
				DEBIMETERCOEF_VALIDATION_ERR: 'Debimeter is a required numeric value',
				FLOW_RATE: 'Flow Rate',
				ENTER_FLOW_RATE: 'Please enter flow rate',
				FLOWRATE_VALIDATION_ERR: 'Flow rate is a required numeric value',
				IRRIGATION_REQUEST_TYPE: 'Irrigation Type',
				OFFLINE: 'Offline',
				ELEVATION: 'Elevation',
				ENTER_ELEVATION: 'Please enter elevation',
				ELEVATION_VALIDATION_ERR: 'Please enter a valid elevation',
				LATITUDE: 'Latitude',
				ENTER_LATITUDE: 'Please enter latitude',
				LATITUDE_VALIDATION_ERR: 'Please enter a valid latitude',
				LONGTITUDE: 'Longtitude',
				ENTER_LONGTITUDE: 'Please enter longtitude',
				LONGTITUDE_VALIDATION_ERR: 'Please enter a valid longtitude',
				COOP: 'Associated Coop',
				COOP_MUST_BE_SELECTED_ERR: 'You have to select the associated coop',
				PRICE_RATE: 'Price Rate',
				EDIT_WELL: 'Edit Well',
				PARAMETERS: 'Well Parameters',
				PUMP_PRICE_RATE: 'Pump Price Rate',
				COOP_NAME: 'Coop',
				EDIT: {
					UPDATE_MESSAGE: 'Well has been updated',
					ADD_MESSAGE: 'Well has been created'
				},
				CONTINUOUS_IRRIGATION: 'Continuous Irrigation',
				ENERGY_ANALYZER: 'Energy Analyzer',
				SETTINGS: 'Well Settings',
				WELL_SETTINGS_DEFAULT_MESSAGE: 'Default Message',
				WELL_SETTINGS_MESSAGE: 'Message',
				SETTINGS_SAVED: 'Well settings are saved',
				GENERAL_SETTINGS: 'General Settings',
				ALARM_MESSAGES: 'Alarm Messages',
				PRODUCT_ID: 'Hardware',
				FW_VERSION: 'Software',
			},
			DEVICE: {
				STATUS: {
					TITLE: 'Status',
					UNKNOWN: 'Unknown',
					INACTIVE: 'Inactive',
					ACTIVE: 'Active',
					FIRMWARE_UPDATING: 'Firmware Updating',
					WAITING_TO_SET_TIME: 'Waiting To Set Time',
					SETTING_TIME: 'Setting Time',
					NOT_CONNECTED: 'Not Connected',
				},
			},
			WELL_PUMP_PRICE_RATE: {
				TITLE: 'Pump Price Rates',
				PRICE_RATE: 'Price Rate',
				PRICE_RATE_TYPE: 'Price Type',
				START_DATE: 'Start Date',
				END_DATE: 'End Date',
				ACTIONS: 'Actions',
				NEW_PUMP_PRICE_RATE: 'New Pump Price Rate',
				CREATE_NEW_PUMP_PRICE_RATE: 'Create New Pump Price',
				EDIT_PRICE_RATE: 'Update New Pump Price',
				NEW_PRICE_RATE: 'New Price Rate',
				DELETE_PRICE_RATE: {
					DELETE: 'Delete',
					TITLE: 'Delete Pump Price Rate',
					DESCRIPTION: 'The price rate will be deleted. Do you want to continue?',
					WAIT_DESCRIPTION: 'Price rate is being deleted...',
					MESSAGE: 'Price rate deleted',
					ERR_MESSAGE: 'Price rate could not be deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Pump price rate is updated',
					ADD_MESSAGE: 'Pump price rate added'
				},
				ENTER_PRICE_TYPE: 'Please select a price type',
				ENTER_PRICE_RATE: 'Please enter the price rate',
				ENTER_START_DATE:'Please enter a start date',
				EXTERNAL_FIELD_COEF: 'External field coefficient',
				ENTER_EXTERNAL_FIELD_COEF: 'Please enter the external field coefficient',
				VALIDATION_EXT_FIELD: 'Enter a value between 1 and 10.',
				VALIDATION_START_DATE: 'Enter a valid start date',
				VALIDATION_ERR_PUMP_PRICE_RATE: 'Enter a valid price rate',
			},
			PARAMETERS: {
				TABS: {
					VIEW: 'Parameters',
					FIRMWARE: 'Software',
					DEVICE: 'Device',
					SERVER: 'Server'
				},
				READ_PARAMETERS: {
					TITLE: 'Read Parameters',
					DESCRIPTION: 'Parameters will be read. Do you want to continue?',
					WAIT_DESCRIPTION: 'Parameters are being read...',
					MESSAGE: 'Parameters are read',
					ERR_MESSAGE: 'Parameters could not be read'
				},
				UPDATE_FIRMWARE: {
					TITLE: 'Start Firmware Update',
					DESCRIPTION: 'Firmware update will be started. Do you want to continue?',
					WAIT_DESCRIPTION: 'Firmware update is being started...',
					MESSAGE: 'Firmware update started',
					ERR_MESSAGE: 'Firmware update could not be started'
				},
				SET_TIME: 'Set time',
				FORWARD_TIME: 'Forward time (secs)',
				SET_TIME_ZONE: 'Set time zone (GMT + X mins)',
				SET_AUTO_UPDATE: 'NTP use',
				LAST_READ_TIME: 'Last read time',
				SERVER_IP: 'Server ip address',
				KEEP_ALIVE_PERIOD: 'Keep alive period',
				LOW_POWER: 'Low power mode',
				SET: 'Set',
				MODEM_ON_PERIOD: 'Modem power on period',
				MODEM_OFF_PERIOD: 'Modem power off period',
				LOAD_FACTORY_DEFAULTS: 'Load factory default settings',
				FORMAT_ARCHIVE: 'Format archive memory',
			},
			WELL_ENERGY: {
				TITLE: 'Energy Analysis',
				TABS: {
					BASIC: 'Basic data',
					ADVANCED: 'Advanced data',
					GRAPHS: 'Graphics'
				}
			},
			WELL_INPUTS: {
				TITLE: 'Inputs',
				LAST_INPUT: 'Last Reading',
				PREVIOUS_INPUTS: 'Readings',
				DATE: 'Date',
				MAIN_POWER: 'Main power',
				SENSOR_POWER: 'Sensor power',
				CPU_TEMPERATURE: 'CPU temperature',
				DIGITAL_INPUT_1: 'Digital input 1',
				DIGITAL_INPUT_2: 'Digital input 2',
				DIGITAL_INPUT_3: 'Digital input 3',
				DIGITAL_INPUT_4: 'Digital input 4',
				ANALOG_INPUT_1: 'Analog input 1',
				ANALOG_INPUT_2: 'Analog input 2',
				ANALOG_INPUT_3: 'Analog input 3',
				ANALOG_INPUT_4: 'Analog input 4',
			},
			FIELDS: {
				FIELD: 'Field',
				FIELD_LIST: 'Field List',
				NEW_FIELD: 'New Field',
				CREATE_NEW_FIELD: 'Create new Field',
				FIELD_NAME: 'Field Name',
				ENTER_FIELD_NAME: 'Please enter field name',
				BLOCK_NUMBER: 'Block Number',
				ENTER_BLOCK_NUMBER: 'Please enter block number',
				BLOCK_NUMBER_VALIDATION_ERR: 'Block number is required',
				PARCEL_NUMBER: 'Parcel Number',
				ENTER_PARCEL_NUMBER: 'Please enter parcel number',
				PARCEL_NUMBER_VALIDATION_ERR: 'Parsel number is required',
				DECARES: 'Area (daa)',
				DECARES_VALIDATION_ERR: 'Area is required',
				ENTER_DECARES: 'Please enter area',
				EXTERNAL_FIELD: 'External field',
				EDIT_FIELD: 'Update Field',
				EDIT: {
					UPDATE_MESSAGE: 'Field updated',
					ADD_MESSAGE: 'Field created'
				},
				GENERAL_INFO: 'General Info',
				MEMBER_AND_WELLS: 'Field Owner and Wells',
				FIELD_OWNER: 'Field Owner',
				WELLS_THAT_CANBE_USED: 'Well that can be used',
				SELECT_FIELD_OWNER: 'Please select field owner',
				SELECT_WELLS_THAT_CANBE_USED: 'Please select wells that can be used',
				POLYGON_COORDINATES: 'Polygon Coordinates',
				ENTER_POLYGON_COORDINATES: 'Enter in lat long pairs',
				POLYGON_VALIDATION_ERR: 'Enter lat long pairs at each line',
				ERROR_IN_POLYGON_COORDINATES: 'Error in polygon coordinates',
				ERROR_AT_LEAST_3_COORDINATES_REQUIRED: 'At least 3 coordinates required'
			},
			PROFILE: {
				UPDATE_PROFILE: 'Update Password',
				CHANGE_PASSWORD: 'Change Password',
				NAME: 'Name',
				ENTER_NAME: 'Please enter your name',
				SURNAME: 'Surname',
				ENTER_SURNAME: 'Please enter your surname',
				HEADER: 'Update the info that you want to change',
				PHONE: 'Phone',
				ENTER_PHONE: 'Please enter your phone',
				PHONE_ERR: 'Please enter a valid phone',
				EMAIL: 'E-Mail',
				ENTER_EMAIL: 'Please enter your email',
				EMAIL_ERR: 'Please enter a valid email',
				IDENTITY_NB: 'Identity Number',
				ENTER_IDENTITY_NB: 'Please enter your identitiy number',
				IDENTITY_NB_ERR: 'Please enter a valid identity number',
				REGISTRATION_NB_ERR: 'Please enter a valid registration number',
				PASSWORD: 'Old Password',
				ENTER_PASSWORD: 'Enter your old password (min 6 char)',
				PASSWORD_ERR: 'Please enter your old password (min 6 char)',
				NEW_PASSWORD: 'New Password',
				ENTER_NEW_PASSWORD: 'Enter your new password (min 6 char)',
				NEW_PASSWORD_ERR: 'Enter your new password (min 6 char)',
				NEW_PASSWORD_R: 'New Password Again',
				ENTER_NEW_PASSWORD_R: 'Please enter your new password again(min 6 char)',
				NEW_PASSWORD_R_ERR: 'Passwords do not match',
				UPDATE_PASSWORD_SUCCESS: 'Password updated',
				UPDATE_PROFILE_SUCCESS: 'Profile updated',
				UPDATE_PROFILE_FAILURE: 'Profile could not be updated',

			},
			REPORTS: {
				TOTAL_METER_CUBE: 'Amount (m3)',
				ACTIVE_DURATION: 'Active Dur.',
				TOTAL_DURATION: 'Total Dur.',
				IRRIGATION_DURATION: 'Irrigation Dur.',
				TOTAL_SPENT: 'Spent (TL)',
				EXTERNAL_INTERNAL_FIELD: 'Int/Ext Field',
				DEPOSIT: 'Deposit (TL)',
				DEPOSIT_SHORT: 'D(TL)',
				SPENT: 'Spent (TL)',
				SPENT_SHORT: 'S (TL)',
				BALANCE: 'Balance (TL)',
				BALANCE_SHORT: 'B (TL)',
				ACTION_TYPE: 'Action Type',
				ACTION_TYPE_SHORT: 'Action',
				PAYMENT_TYPE: 'Payment Type',
				OPERATOR: 'Operator',
				ACTION_TYPE_LOAD: 'Load',
				ACTION_TYPE_RETURN: 'Return',
				ACTION_TYPE_IRRIGATION: 'Irrigation',
				ACTION_TYPE_DEBT: 'Debt/Correction',
				ACTION_TYPE_CREDIT_CARD_COMISSION: 'Credit Card Comission',
				ACTION_TYPE_SERVICE: 'Service',
				PAYMENT_TYPE_CASH : 'Cash',
				PAYMENT_TYPE_CREDITCARD : 'Credit Card',
				PAYMENT_TYPE_MONEYORDER : 'Money Order',
				SELECT_ACTION_TYPE: 'Select Action Type',
				EXTERNAL: 'External',
				INTERNAL: 'Internal',
				PRINT: 'Print',
				WELL: 'Well',
				INCOME: 'Income',
				EXPENSE: 'Expense',
				NET: 'Net',
				PERIOD: 'Period',
				PAYMENTS: 'Payments',
				IRRIGATION_SUMMARY: 'Irrigation Summary',
				REPORT_DESCRIPTION: 'Report Description',
				REPORT_FOOTER_ID:'Report Id:'
			},
			USERS: {
				USERS: 'Users',
				USERS_LIST: 'Users List',
				DIRECTORATE_USER_LIST: 'Directorate User List',
				COOP_USER_LIST: 'Coop User List',
				MEMBER_USER_LIST: 'Member User List',
				NEW_USER: 'New User',
				EDIT_USER: 'Edit User',
				CREATE_NEW_USER: 'Create New User',
				USER_INFO: 'User Information',
				DIRECTORATE_USER_INFO: 'Directorate User Information',
				COOP_USER_INFO: 'Coop User Information',
				MEMBER_USER_INFO: 'Member User Information',
				DEPEND_ON_MEMBER: 'Depend On Member',
				DEPEND_ON_COOP: 'Depend On Coop',
				DEPEND_ON_DIRECTORATE: 'Depend On Directorate',
				USER_PASSWORD: 'User Password',
				ENTER_USERNAME: 'Enter user name',
				USERNAME: 'User Name',
				ADMIN_CHECK: 'Admin',
				EDIT_PASSWORD: 'Edit Password',
				SELECT_DIRECTORATE: 'Select directorate',
				SELECT_COOP: 'Select coop',
				EMPTY_DIRECTORATE_ERR: 'You have to select a directorate',
				EDIT: {
					PASSWORD_MESSAGE: 'Password has been updated',
					UPDATE_MESSAGE: 'User has been updated',
					ADD_MESSAGE: 'User has been created'
				},
			},
			SMS_SETTINGS: {
				TITLE: 'SMS Provider Settings',
				USERNAME: 'User Name',
				USERNAME_REQUIRED: 'User name is required',
				USERNAME_ENTER: 'Please enter user name',
				PASSWORD: 'Password',
				PASSWORD_REQUIRED: 'Password is required',
				PASSWORD_ENTER: 'Please enter password',
				SAVE_SUCCESS: 'SMS provider settings are saved',
				SAVE_FAILURE: 'SMS provider settings could not be saved'
			},
			EMAIL_SETTINGS: {
				TITLE: 'E-Mail Provider Settings',
				SENDER_ADDRESS: 'Sender Address',
				SENDER_ADDRESS_REQUIRED: 'Sender Address is required',
				SENDER_ADDRESS_ENTER: 'Please enter sender address',
				SENDER_NAME: 'Sender Name',
				SENDER_NAME_REQUIRED: 'Sender Name is required',
				SENDER_NAME_ENTER: 'Please enter sender name',
				SMTP_HOST: 'SMTP Host',
				SMTP_HOST_REQUIRED: 'SMTP Host is required',
				SMTP_HOST_ENTER: 'Please enter SMTP host',
				SMTP_PORT: 'SMTP Port',
				SMTP_PORT_REQUIRED: 'SMTP Port is required',
				SMTP_PORT_ENTER: 'Please enter SMTP port',
				SMTP_USERNAME: 'SMTP User Name',
				SMTP_USERNAME_REQUIRED: 'SMTP user name is required',
				SMTP_USERNAME_ENTER: 'Please enter SMTP user name',
				SMTP_PASSWORD: 'SMTP Password',
				SMTP_PASSWORD_REQUIRED: 'SMTP password is required',
				SMTP_PASSWORD_ENTER: 'Please enter SMTP password',
				SAVE_SUCCESS: 'E-Mail provider settings are saved',
				SAVE_FAILURE: 'E-Mail provider settings could not be saved'
			},
			IRRIGATION_FORM: {
				PARTNER_NO: 'Partner Nb',
				DISTRICT: 'District',
				SHEET_NO: 'Sheet Nb',
				PARCEL_NO: 'Parcel Nb',
				WELL_NO: 'Well Nb',
				POSITION: 'Position',
				PLANT_TYPE: 'Plant Type',
				EXTENT: 'Extent (min)',
				IRRIGATION_START_DATE: 'Irrigation Start Date',
				IRRIGATION_END_DATE: 'Irrigation End Date',
				IRRIGATION_START_TIME: 'Irrigation Start Time',
				IRRIGATION_END_TIME: 'Irrigation End Time',
				IRRIGATION_DURATION: 'Irrigation Duration (hr)',
				IRRIGATION_AMOUNT: 'Irrigation Amount (m3)',
				IRRIGATION_FEE: 'Irrigation Fee',
				AMOUNT_TO_BE_PAID: 'Amount to be paid',
				PRINT_TITLE: 'Print',
				PRINT: 'Print',
				USER_SIGN1: 'Users ',
				USER_SIGN2: 'Signature',
				CRAFTSMAN_SIGN1: 'Craftsman',
				CRAFTSMAN_SIGN2: 'Name Surname Signature',
				LABEL1 : 'IRRIGATION FORM',
				LABEL2 : 'For Partners',
				LABEL3 : 'Irrigation',
			},
			MONTHLY_SUMMARY_FORM: {
				IRRIGATIONS:'Irrigations',
				PAYMENTS:'Payments',
				COOP_RESPONSIBLE:'Coop responsible',
				NAME:'Name Surname: ',
				SIGN:'Sign: ',
				REPORT_CREATED_DATE:'Report create date: ',
				REPORT_CREATER:'Report created by: ',
				IDENTITY:'TC: ',
				DATE:'Date: ',
				MEMBER:'Member:',
				MEMBER_NUMBER:'Member Number: ',
				ERR_MESSAGE:'No records were found to be reported within the entered time range.',
				COOP:'Cooperative:',
			},
			CROPTYPE: {
				APPLE: 'Apple',
				PEAR: 'Pear',
				APRICOT: 'Apricot',
				CHERRY: 'Cherry',
				SOUR_CHERRY: 'Sour Cherry',
				PEACH: 'Peach',
				PLUM: 'Plum',
				RICE: 'Rice',
				CARROT: 'Carrot',
				CELERIAC: 'Celeriac',
				KOHLRABI: 'KohlRabi',
				RADISH: 'Radish',
				TOMATO: 'Tomato',
				AUBERGINE: 'Aubergine',
				PEPPER: 'Pepper',
				ONION: 'Onion',
				SHALLOT: 'Shallot',
				LEEK: 'Leek',
				GARLIC: 'Garlic',
				POTATO: 'Potato',
				BEET: 'Beet',
				GRAPE: 'Grape',
				LEMON: 'Lemon',
				ORANGE: 'Orange',
				TANGERINE: 'Tangerine',
				WHEAT: 'Wheat',
				OAT: 'Oat',
				BARLEY: 'Barley',
				RYE: 'Rye',
				MAIZE: 'Mazie',
				ALMOND: 'Almond',
				WALNUT: 'Walnut',
				HAZELNUT: 'Hazelnut',
				CUCUMBER: 'Cucumber',
				MELON: 'Melon',
				SQUASH: 'Squash',
				PUMPKIN: 'Pumpkin',
				WATERMELON: 'Water Melon',
				CABBAGE: 'Cabbage',
				ENDIVE: 'Endive',
				LETTUCE: 'Lettuce',
				RAPE_SEED: 'Rape Seed',
				OLIVE: 'Olive',
				STRAWBERRY: 'Strawberry',
			},
			CROP: {
				LIST : 'Crop List',
				NAME: 'Crop Name',
				WATER_NEED: 'Water Need',
				DEFAULT_WATER_NEED: 'Default Water Need',
				WATER_NEED_VALIDATION_ERR: 'Water need is a required numeric value',
				PLANTED_CROPS: 'Planted Crops',
				YEAR: 'Year',
				ADD_CROP: 'Add Crop',
				CROP: 'CROP',
				SELECT_CROP: 'Please select a crop',
				ENTER_PERCENT: 'Please enter planting percent',
				ENTER_VALID_PERCENT: 'Please enter a valid planting percent',
				INVALID_TOTAL_PERCENT: 'Total percent cannot be greater than 100',
				INVALID_CROP_PERCENT: 'Crop percent must be greater than 0',
				SEASON: 'Season',
				SELECT_SEASON: 'Please select season',
				PLANTING_DATE: 'Planting Date',
				SELECTED_CROPS: 'Selected Crops',
				CROPS_TO_BE_IRRIGATED: 'Crops to be Irrigated',
				HARVESTED: 'Harvested',
				ESTIMATED_IRRIGATION: 'Estimated Irrigation (m3)',
				ACTUAL_IRRIGATION: 'Actual Irrigation (m3)',
				NO_CROP_DEF: 'No crop definiton found',
			},
			RECEIPT: {
				NO: 'No:',
				RECEIPT : 'Receipt',
				RECEIPT_NO : 'Receipt Nb',
				RECEIPT_NO_SHORT : 'Receipt',
				PAYMENT_TYPE: 'Payment Type',
				PAYMENT: 'Payment',
				BACK_PAYMENT: 'Back Pay',
				CREDIT_CARD_COMMISSION: 'CC Comission',
				DEBT: 'Debt/Correction',
				LATE_FEE: 'Late Fee',
				CREDIT_YEAR: 'Credit Year',
				AMOUNT: 'Amount',
				AMOUNT1: 'Amount (TL)',
				AMOUNT_SHORT: 'AMount',
				FREE_TEXT1: 'Yalnız',
				FREE_TEXT2: 'Lira',
				FREE_TEXT3: 'dan',
				FREE_TEXT3_2: 'e',
				FREE_TEXT4: 'tahsil edilmiştir',
				FREE_TEXT4_2: 'iade edilmiştir',
				FREE_TEXT5: 'adına borç bakiyesi olarak yazılmıştır',
				FREE_TEXT5_2: 'bakiyesi olarak yazılmıştır',
				SIGN1: 'Yevmiye Def. Mad.',
				SIGN2: 'Kasa Görevlisi',
				SIGN3: 'Başkan',
				CONFIRM_PRINT_TITLE: 'Print Receipt',
				CONFIRM_PRINT_DESCRIPTION: 'Do you want to print a receipt?',
			},
			HYDRANTS: {
				HYDRANT: 'Hydrant',
				HYDRANT_LIST: 'Hydrant List',
				NEW_HYDRANT: 'New Hydrant',
				CREATE_NEW_Hydrant: 'Create New Hydrant',
				HYDRANT_NAME: 'Hydrant Name',
				ENTER_HYDRANT_NAME: 'Please enter hydrant name',
				IMEI: 'Imei',
				ENTER_IMEI: 'Please enter imei',
				IMEI_VALIDATION_ERR: 'Imei should be 15 digit number',
				PUMP_ID: 'Pump ID',
				ENTER_PUMP_ID: 'Please enter pump id',
				PUMPID_VALIDATION_ERR: 'Pump id is a required numeric value',
				HAS_DEBIMETER: 'Has Debimeter',
				HAS_REMOTE_RFID_SCAN: 'Has Remote RFID Scan',
				DEBIMETER_ID: 'Debimeter ID',
				ENTER_DEBIMETER_ID: 'Please enter debimeter id',
				DEBIMETERID_VALIDATION_ERR: 'Debimeter is a required numeric value',
				VALVE_ID: 'Valve ID',
				ENTER_VALVE_ID: 'Please enter valve id',
				VALVEID_VALIDATION_ERR: 'Valve id is a required numeric value',
				DEBIMETER_COEF: 'Debimeter Coefficient',
				ENTER_DEBIMETER_COEF: 'Pleae enter debimeter coefficient',
				DEBIMETERCOEF_VALIDATION_ERR: 'Debimeter is a required numeric value',
				FLOW_RATE: 'Flow Rate',
				ENTER_FLOW_RATE: 'Please enter flow rate',
				FLOWRATE_VALIDATION_ERR: 'Flow rate is a required numeric value',
				IRRIGATION_REQUEST_TYPE: 'Irrigation Type',
				OFFLINE: 'Offline',
				ELEVATION: 'Elevation',
				ENTER_ELEVATION: 'Please enter elevation',
				ELEVATION_VALIDATION_ERR: 'Please enter a valid elevation',
				LATITUDE: 'Latitude',
				ENTER_LATITUDE: 'Please enter latitude',
				LATITUDE_VALIDATION_ERR: 'Please enter a valid latitude',
				LONGTITUDE: 'Longtitude',
				ENTER_LONGTITUDE: 'Please enter longtitude',
				LONGTITUDE_VALIDATION_ERR: 'Please enter a valid longtitude',
				COOP: 'Associated Coop',
				COOP_MUST_BE_SELECTED_ERR: 'You have to select the associated coop',
				PRICE_RATE: 'Price Rate',
				EDIT_HYDRANT: 'Edit Hydrant',
				SETTINGS: 'Hydrant Settings',
				PARAMETERS: 'Hydrant Parameters',
				PRICE_TARIFF: 'Price Tariff',
				COOP_NAME: 'Coop',
				IMEINO_EXIST: 'imei number exist on the system',
				VALVE: 'Valve',
				ENDPOINT: 'Outlet',
				CREATE: {
					ADD_MESSAGE: 'Hydrant has been created'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Hydrant has been updated',
				},
				SETTINGS_SAVED: 'Hydrant settings are saved',
				CONTINUOUS_IRRIGATION: 'Continuous Irrigation',
				ENERGY_ANALYZER: 'Energy Analyzer',
				MSGS : {
					SELECT_ONE_VALVE: 'At least one outlet should be selected',
					VALVE_IDS_MUST_BE_UNIQUE: 'Valve ids should be unique',
					FLUXMETER_IDS_MUST_BE_UNIQUE: 'Fluxmeter ids should be unique',
					SELECT_IRRIGATION_REQUEST_TYPE: 'You have to select the irrigation request type'
				},
				PRODUCT_ID: 'Hardware',
				FW_VERSION: 'Software',
			},
			TANKS: {
				TANK: 'Tank',
				TANK_NAME: 'Tank Name',
				ENTER_TANK_NAME: 'Enter tank name',
				TANK_LIST: 'Tank List',
				NEW_TANK: 'New Tank',
				IMEI: 'Imei',
				ENTER_IMEI: 'Please enter imei',
				IMEI_VALIDATION_ERR: 'Imei should be 15 digit number',
				OFFLINE: 'Offline',
				ELEVATION: 'Elevation',
				ENTER_ELEVATION: 'Please enter elevation',
				ELEVATION_VALIDATION_ERR: 'Please enter a valid elevation',
				LATITUDE: 'Latitude',
				ENTER_LATITUDE: 'Please enter latitude',
				LATITUDE_VALIDATION_ERR: 'Please enter a valid latitude',
				LONGTITUDE: 'Longtitude',
				ENTER_LONGTITUDE: 'Please enter longtitude',
				LONGTITUDE_VALIDATION_ERR: 'Please enter a valid longtitude',
				SETTINGS: 'Tank Settings',
				SETTINGS_SAVED: 'Hydrant settings are saved',
				CREATE_NEW_TANK: 'Create New Tank',
				EDIT_TANK: 'Edit Tank',
				PARAMETERS: 'Tank Parameters',
				IMEINO_EXIST: 'imei number exist on the system',
				PRODUCT_ID: 'Hardware',
				FW_VERSION: 'Software',
				IRRIGATION_REQUEST_TYPE: 'Irrigation Type',
				DEBIMETER_ID: 'Debimeter ID',
				ENTER_DEBIMETER_ID: 'Please enter debimeter id',
				DEBIMETERID_VALIDATION_ERR: 'Debimeter is a required numeric value',
				DEBIMETER_COEF: 'Debimeter Coefficient',
				ENTER_DEBIMETER_COEF: 'Pleae enter debimeter coefficient',
				DEBIMETERCOEF_VALIDATION_ERR: 'Debimeter is a required numeric value',
				FLOW_RATE: 'Flow Rate',
				ENTER_FLOW_RATE: 'Please enter flow rate',
				FLOWRATE_VALIDATION_ERR: 'Flow rate is a required numeric value',
				MSGS: {
					PUMPID_VALIDATION_ERR: 'Pump id is a required numeric value',
					SELECT_ONE_PUMP: 'Please select at least one pump',
					PUMP_IDS_MUST_BE_UNIQUE: 'Pump ids should be unique',
					SELECT_IRRIGATION_REQUEST_TYPE: 'You have to select the irrigation request type',
					FLUXMETER_IDS_MUST_BE_UNIQUE: 'Fluxmeter ids should be unique',
				},
				EDIT: {
					UPDATE_MESSAGE: 'Tank has been updated',
					ADD_MESSAGE: 'Tank has been created'
				},
				CREATE: {
					ADD_MESSAGE: 'Tank has been created'
				},
				DIGITAL_INPUTS: { 
					TITLE: 'Digital Input',
					RUN_MODE: 'Run Mode',
					MSGS: {
						DIGITAL_INPUTS_IDS_MUST_BE_UNIQUE: 'Digital input ids should be unique',
					},
				},
				ANALOG_INPUTS:{ 
					TITLE: 'Analog Input',
					FLOW: 'Flow',
					MSGS: {
						ANALOG_INPUTS_IDS_MUST_BE_UNIQUE: 'Analog input ids should be unique',
					},
				},
				PUMP: {
					NAME: 'Pump',
					TITLE: 'Pump No',
					STATE: 'Active',
					MSGS: {
						PUMP_IDS_MUST_BE_UNIQUE: 'Pump output ids should be unique',
					},
				},
				SOLENOID: {
					TITLE: 'Solenoid',
					STATE: 'Active',
					MSGS: {
						SOLENOID_IDS_MUST_BE_UNIQUE: 'Solenoid output ids should be unique',
					},
				},
				MOTOR_VALVE: {
					TITLE: 'Motor Valve',
					STATE: 'Active',
					MSGS: {
						MOTOR_IDS_MUST_BE_UNIQUE: 'Motor valve ids should be unique',
					},
				},
			},
			PRICETARIFFS: {
				PRICE_RATE: 'Price Rate',
				ENTER_PRICE_RATE: 'Please enter the price rate',
				START_DATE: 'Start Date',
				END_DATE: 'End Date',
				PRICETARIFF_LIST: 'Price Tariff List',
				PRICETARIFFRATE_LIST: 'Price Tariff Rate List',
				NEW_PRICE_TARIFF: 'New Price Tariff',
				EDIT_PRICETARIFF: 'Edit Price Tariff',
				PRICE_RATE_TYPE: 'Price Type',
				ENTER_PRICE_RATE_TYPE: 'Enter Price Type',
				ADD: 'Add',
				PRICETARIFF: 'Price Tariff',
				END_DATE_MUST_BE_AFTER_START_DATE_OR_MUST_BE_NULL: 'End date must be after start date or empty',
				START_DATE_MUST_NOT_BE_NULL: 'Start date can not be null',
				PRICE_RATE_MUST_NOT_BE_NULL: 'Price rate can not be null',
				VALIDATION_PRICE_RATE: 'Enter a valid price rate',
				VALIDATION_EXT_FIELD:'Enter a value between 1 and 10.',
				VALIDATION_START_DATE: 'Enter a valid start date',
				EXTERNAL_FIELD_COEF:'External field coefficient',
				ENTER_EXTERNAL_FIELD_COEF:'Please enter the external field coefficient',
				EDIT_PRICE_RATE: 'Update Price Rate',
				HYDRANT_PRICE_TARIFF: 'Hydrant Price Tariff',
				TANK_PRICE_TARIFF: 'Tank Price Tariff',
				DELETE_PRICE_RATE: {
					DELETE: 'Delete',
					TITLE: 'Delete Price Rate',
					DESCRIPTION: 'The price rate will be deleted. Do you want to continue?',
					WAIT_DESCRIPTION: 'Price rate is being deleted...',
					MESSAGE: 'Price rate deleted',
					ERR_MESSAGE: 'Price rate could not be deleted'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Price Tariff has been updated',
					ADD_MESSAGE: 'Price Tariff has been created'
					},
			},
			LINES: {
				CREATE_NEW_LINE: 'New Line',
				LINE_LIST: 'Line List',
				EDIT_LINE: 'Edit Line',
				OFFLINE: 'Offline',
				COOP_NAME: 'Coop',
				NEW_LINE: 'New Line',
				LINE_NAME: 'Line Name',
				ENTER_LINE_NAME: 'Please enter line name',
				IMEI: 'Imei',
				ENTER_IMEI: 'Please enter imei',
				IMEI_VALIDATION_ERR: 'Imei should be 15 digit number',
				ELEVATION: 'Elevation',
				ENTER_ELEVATION: 'Please enter elevation',
				ELEVATION_VALIDATION_ERR: 'Please enter a valid elevation',
				LATITUDE: 'Latitude',
				ENTER_LATITUDE: 'Please enter latitude',
				LATITUDE_VALIDATION_ERR: 'Please enter a valid latitude',
				LONGTITUDE: 'Longtitude',
				ENTER_LONGTITUDE: 'Please enter longtitude',
				LONGTITUDE_VALIDATION_ERR: 'Please enter a valid longtitude',
				COOP: 'Associated Coop',
				COOP_MUST_BE_SELECTED_ERR: 'You have to select the associated coop',
				IMEINO_EXIST: 'imei number exist on the system',
				SELECTED_NODES: 'Selected Nodes',
				AVAILABLE_NODES: 'Available Nodes',
				EDIT: {
					UPDATE_MESSAGE: 'Line has been updated',
					ADD_MESSAGE: 'Line has been created'
				},
				TOGGLE_OFFLINE: 'Offline',
				TOGGLE_ONLINE: 'Online',
				TOGGLE_FIELD: 'Field',
				TOGGLE_WELL: 'Well',

			}

		},
		PAGINATOR: {
			ITEMS_PER_PAGE: 'Items per page',
			NEXT_PAGE: 'Next page',
			PREVIOUS_PAGE: 'Peevious page',
			FIRST_PAGE: 'First page',
			LAST_PAGE: 'Last page',
		},
		FAQ: {
			TITLE: 'Frequently Asked Questions',
			NEW: 'Add Question & Answer',
			SEND: 'Send Question',
			EDIT: 'Edit',
			DELETE: 'Delete',
		},
	}
};
